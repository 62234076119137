import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import './plugins/axios'
import './plugins/sanitizer'

console.log('host', process.env.VUE_APP_API_HOST)

new Vue({
  render: h => h(App),
}).$mount('#app')
